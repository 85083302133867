/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const UudenVuodenVoimaretki = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/hiihtovaellukset/uuden-vuoden-voimaretki',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Uuden vuoden voimaretki',
    description:
      'Hiihtäen kohti uutta vuotta, uuden vuoden lupauksia luonnon helmassa! Aloita vuosi 2024 energisesti ja positiivisesti uudenvuoden retkellä naisten kanssa Keimiöjärven tuvalle.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="uuden-vuoden-voimaretki"
    >
      <div className="camp-text-content">
        <h2>Uuden vuoden voimaretki</h2>
        <h3>30.12.2023-1.1.2024</h3>
        <p className="bolded">
          Hiihtäen kohti uutta vuotta, uuden vuoden lupauksia luonnon helmassa!
          Aloita vuosi 2024 energisesti ja positiivisesti uudenvuoden retkellä
          naisten kanssa Keimiöjärven tuvalle.
        </p>
        <br />
        <p>
          Vuoden vaihteessa kaamos on päättymässä näillä leveysasteilla. Lumiset
          maisemat ja päivän aikana muuttuva valo tunturien syleilyssä saa
          mielen rauhoittumaan.
        </p>
        <br />
        <p className="bolded">Uuden vuoden retken kulku</p>
        <p>
          Tapaamme Keimiöjärven parkkipaikalla lauantaina 30.12.2023 klo 10.00.
          Kun kaikki on valmista, lähdemme hiihtämään polkua pitkin
          Keimiötunturin juurta osittain myötäillen. Mystisen tunnelmallisessa
          ja lumisessa vanhassa metsässä hiihdämme kohti Keimiöjärven rantaa.
          Järvi sijaitsee Keimiötunturin, Sammaltunturin, Koivakeron ja
          Mustakeron välissä. Matkamme on tasaista ja rauhallista, ja voit
          nauttia luonnon kauneudesta ja hiljaisuudesta. Hiihtomatka on noin 6
          kilometrin suuntaansa tasaisessa maastossa. Tuvassa yövytään kaksi
          yötä. Uuden vuoden aattona tehdään sään salliessa lyhyt päiväretki
          järven toiselle tuvalle. 1.1.2024 lähdemme puolen päivän maissa
          takaisin kohti Keimiöjärven parkkipaikkaa.
        </p>
        <br />
        <p className="bolded">Majoittuminen Keimiöjärvellä</p>
        <p>
          Keimiöjärven tupa tarjoaa paikan nauttia uuden vuoden
          rauhallisuudesta, kuunnellen hiljaisuutta ja tehden herkullista ruokaa
          tuvan lämmössä, kaukana rakettien paukkeesta.
          <br />
          Tuvassa voit esimerkiksi lukea kirjaa, joogata, palata korttia, luoda
          uuden vuoden lupauksia ja tavoitteita tai vain yksinkertaisesti olla.
          Nauttia olemassa olevasta hetkestä ja keskittyä omaan hyvinvointiisi.
          Tuvasta löytyy kamiina, kaasuliesi, kuivakäymälä, puuvarasto,
          välttämättömät keitto- ja ruokailuvälineet, patjat, tyynyt ja huovat.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg1.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg2.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Mitä sinun tarvitsee huolehtia retkeä varten?</p>
        <p>
          Sinulle tulee huolehdittavaksi omat kuljetukset Keimiöjärven
          parkkipaikalle (suosimme ja suosittelemme kimppakyytejä,) omat ruoat,
          ruokailyvälineet sekä liikkumisvälineesi, jotka voivat olla
          perinteisen sukset karvapohjilla, tunturisukset karvapohjilla,
          liukulumikengät karvapohjilla, splitboard tai rando-sukset. Näiden
          avulla pääset liikkumaan kansallispuiston upeissa maisemissa ja
          nauttimaan talvisesta luonnosta. Jotta saat myös tavarasi ja
          tarvikkeesi tuotua mukanasi, tarvitset joko ison rinkan, ahkion tai
          pulkan millä vedät tarvikkeesi. Tupaan tarvitset mukaasi
          petivaatteiden suojaksi makuupussinsisälakanan tai lakanat ja
          tyynyliinan.
        </p>
      </div>
      <div className="camp-text-content">
        <p className="bolded">Vaatimustaso:</p>
        <p>
          Sinulla ei tarvitse olla aikaisempaa kokemusta talviretkeilystä tai
          hiihtovaeltamisesta, sinun tulee kuitenkin osata hiihtää. Fyysisen
          kunnon puolesta retki vaatii peruskunnon.
        </p>
        <br />
        <p className="bolded">Retken hinta on 250 € (sis alv.)</p>
        <p>
          Hintaan kuuluu: Kaksi yötä Keimiöjärven vuokratuvassa Oppaan palvelut
          retkellä (oppaana Sanni Lampinen) Pakkauslista Apua tarvikkeiden
          lainaamiseen / vuokraamiseen.{' '}
        </p>
        <br />
        <p className="bolded">
          Ilmoittaudu mukaan sähköpostitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          ja toimi näin:
        </p>
        <p>
          OTSIKKO: Hiihto kaamoksen hiljaisuuteen Pallaksella 8.-10.12.2023{' '}
          <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Oletko ollut aikaisemmin retkillämme? Jos olet, millä? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
        </p>
        <br />
        <p className="bolded">Retken varaus- ja peruutusehdot:</p>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 50€. Kun lasku on maksettu, saat paluupostina
          retken materiaalit; pakkauslistan ja retkisuunnitelman.
          <br />
          <br />
          Hiihtovaelluksen loppulaskun 200€ saat sähköpostiisi retken
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          .
          <br />
          <br />
          Jos perut osallistumisesi retkelle missä kohtaa tahansa, pidätämme
          toimitetut retken materiaalien kulut sekä toimistokulut 50€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          .
          <br />
          <br />
          Lue lisää varaus – ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
          <br />
          <br />
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus retken aikana.
          <br />
          <br />
          Vaellukselle otetaan maksimissaan 8 naista, minimi henkilömäärä on 5,
          jotta retki toteutuu.
          <br />
          <br />
          Mikäli sinulla on kysyttävää vaelluksesta, voit olla yhteydessä
          Anetteen joko meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/crosscountry/uudenvuoden-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(
      relativePath: { eq: "images/crosscountry/uudenvuoden-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg5: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg6: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-8.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/crosscountry/uudenvuoden-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default UudenVuodenVoimaretki;
